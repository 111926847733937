import {
    AfterContentInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ComponentRef,
    ContentChildren,
    Input,
    QueryList,
    TemplateRef,
    ViewContainerRef,
    ViewEncapsulation
} from '@angular/core';

import { UiIconComponent } from './ui-icon.component';
import { TUiIconDirection, TUiIconSize, TUiIconSpacing, UiIconSpacing } from './ui-icon.interface';

@Component({
    selector: 'ui-icon-group',
    template: `<ng-content select="ui-icon, a[ui-icon], button[ui-icon]"></ng-content>`,
    styleUrls: ['./ui-icon-group.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: {
        class: 'ui-icon-group',
        '[attr.direction]': 'direction',
        '[attr.spacing]': 'spacing'
    }
})
export class UiIconGroupComponent {
    @Input() size!: TUiIconSize;
    @Input() direction!: TUiIconDirection;
    @Input() spacing: TUiIconSpacing = UiIconSpacing.normal;
}
