import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    Input,
    ViewEncapsulation
} from '@angular/core';

import { TUiIconDirection, TUiIconSize } from './ui-icon.interface';

@Component({
    selector: 'ui-icon-group-list',
    template: `<ng-content select="ui-icon-group"></ng-content>`,
    styleUrls: ['./ui-icon-group-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class UiIconGroupListComponent {
    @HostBinding('class.ui-icon-group-list') baseClass = true;

    @Input() size!: TUiIconSize;
    @Input() direction!: TUiIconDirection;
}
