import {
    ChangeDetectionStrategy,
    Component,
    Input,
    ViewContainerRef,
    ViewEncapsulation
} from '@angular/core';

import { UiIconGroupListComponent } from './ui-icon-group-list.component';
import { UiIconGroupComponent } from './ui-icon-group.component';
import { TUiIconDirection, TUiIconSize } from './ui-icon.interface';

/**
 * Wrapping component for icons, handing sizing and labels. Can be used together
 * with IconGroup and IconGroupList.
 *
 * @example
        <ui-icon-group-list direction="column">
            <ui-icon-group size="small" direction="column">
                <ui-icon>
                    <ui-category-icon
                        svgIcon="type-white"
                    ></ui-category-icon>
                    <ui-label>This is a label</ui-label>
                </ui-icon>
                <ui-icon>
                    <ui-sugar-icon value="0.3"></ui-sugar-icon>
                    <ui-label>This is a label</ui-label>
                </ui-icon>
            </ui-icon-group>

            <ui-icon-group size="small" direction="column">
                <ui-icon>
                    <ui-category-icon svgIcon="type-red"></ui-category-icon>
                    <ui-label>This is a label</ui-label>
                </ui-icon>
                <ui-icon>
                    <ui-sugar-icon value="0.3"></ui-sugar-icon>
                    <ui-label>This is a label</ui-label>
                </ui-icon>
            </ui-icon-group>
        </ui-icon-group-list>
 *
 */
export abstract class Parent {
    abstract name: string;
}

@Component({
    selector: 'ui-icon, a[ui-icon], button[ui-icon]',
    template: `
        <div class="ui-icon-wrapper__element">
            <ng-content></ng-content>
        </div>
        <div class="ui-icon-wrapper__label">
            <ng-content select="ui-label, ui-separator"></ng-content>
        </div>
    `,
    host: {
        class: 'ui-icon-wrapper',
        '[attr.direction]': 'direction',
        '[attr.size]': 'size'
    },
    styleUrls: ['./ui-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    exportAs: 'uiIcon'
})
export class UiIconComponent {
    @Input() direction!: TUiIconDirection;
    @Input() size!: TUiIconSize;

    constructor(private _viewRef: ViewContainerRef) {
        const parentGroupList = this._viewRef.injector.get(UiIconGroupListComponent, null, {
            optional: true,
            skipSelf: true
        });

        if (parentGroupList?.size) {
            this.size = parentGroupList?.size;
            return;
        }

        const parentGroup = this._viewRef.injector.get(UiIconGroupComponent, null, {
            optional: true,
            skipSelf: true
        });

        if (parentGroup?.size) {
            this.size = parentGroup?.size;
        }
    }
}
