export enum UiIconSize {
    'x-small' = 'x-small',
    'small' = 'small',
    'medium' = 'medium',
    'large' = 'large',
    'huge' = 'huge'
}
export type TUiIconSize = keyof typeof UiIconSize;

export enum UiIconDirection {
    'row' = 'row',
    'column' = 'column'
}
export type TUiIconDirection = keyof typeof UiIconDirection;

export enum UiIconSpacing {
    'normal' = 'normal',
    'wide' = 'wide'
}
export type TUiIconSpacing = keyof typeof UiIconSpacing;
