import { ChangeDetectorRef, Directive, Host, HostBinding, Optional } from '@angular/core';

import { UiIconComponentBase } from './ui-icon-abstract.class';
import { TUiIconSize } from './ui-icon.interface';

@Directive({
    selector: '[uiIconElement]',
    standalone: true
})
export class UiIconElementDirective {
    private _size!: TUiIconSize;

    @HostBinding('attr.size') get attrSize() {
        return this._size || null;
    }

    constructor(
        @Optional() @Host() private host: UiIconComponentBase,
        private _cdr: ChangeDetectorRef
    ) {}

    setSize(size: TUiIconSize) {
        this._size = size;

        console.log(this);

        if (this._size && this.host?.size !== this._size) {
            this.host.size = this._size;
        }

        /**
         * @TODO
         * This is a hack to enable the size to bubble through 2 levels of
         * components. This only applies to the Badge-component. Fix this by
         * making "Score" and "Numeric" badges extending the base class, instead
         * of wrapping it.
         *
         * Could alos be done using an IconDirective to handle the basic host
         * bindings?
         */
        setTimeout(() => this._cdr.markForCheck());
    }
}
